import { graphql } from "gatsby";
import moment from "moment";
import "moment/locale/de";
import React, { useContext} from "react";
import "react-multi-carousel/lib/styles.css";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import { Col, Container, Row} from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { renderDynamicComponents } from "../components";
import { carouselBreakpoints } from "../helper/carousel";
import Layout from "../modules/layout";
import SEO from "../modules/seo";
import TeaserCard from "../components/teaserCard";
import Link, {
  getArticleLink,
  getLocalizedLink,
  getPublicationLink,
} from "../helper/link";
import { getCappedText } from "../helper/normalizer";
import { getLocalizedTags, getValue } from "../helper/translation";
import Box from "../components/sidebar/box";
import Button from "../components/button";
import Gallery from "../components/gallery";
import PageHeader from "../components/pageHeader";
import cookiesContext from "../context/cookies/cookiesContext";
import MicrocopyContext from "../context/microcopy/microcopyContext";
import CookiesAreNecessary from "../components/cookiesAreNecessary";

const HomePage = ({ data: { allContentfulBlogArticle }, pageContext }) => {
  const {
    contentComponents = [],
    node_locale,
    settings,
    imageGallery = [],
  } = pageContext;
  const { microcopy } = settings;
  const { cookies } = useContext(cookiesContext);
  const marketingCookies = cookies[2];

  const objImages = {
    images: [],
  };
  if (imageGallery) {
    imageGallery.map(image => {
      objImages["images"].push(image.image);
    });
  }

  // Take component from ContentComponents
  const headerComponent = contentComponents.filter(obj => {
    return obj.internal.type === "ContentfulComponentPageHeader";
  });
  const memberComponent = contentComponents.filter(obj => {
    return obj.internal.type === "ContentfulComponentMembers";
  });
  const sidebarComponent = contentComponents.filter(obj => {
    return obj.internal.type === "ContentfulComponentSidebar";
  });
  const additionalInfoComponent = contentComponents.filter(obj => {
    return obj.internal.type === "ContentfulAdditionalInfo";
  });
  const freeTextComponent = contentComponents.filter(obj => {
    return obj.internal.type === "ContentfulComponentFreeText";
  });

  const allBlogArticle = allContentfulBlogArticle.edges.map(edge => ({
    ...edge.node.articleDetail,
    generalTitle: edge.node.title,
  }));

  const siteSettings = settings;

  const responsive = {
    xl: {
      breakpoint: carouselBreakpoints.xl,
      items: 1,
    },
    l: {
      breakpoint: carouselBreakpoints.l,
      items: 1,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      items: 1,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 1,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 1,
    },
  };

  moment.locale(node_locale.split("-")[0]);

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      {headerComponent ? (
        headerComponent.length > 1 ? (
          <div className="headersContainer">
            {headerComponent.map(component => {
              const params = { ...component, bottomAligned: true };
              return (
                <div className="headerItemContainer">
                  <PageHeader lang={node_locale} {...params} />
                </div>
              );
            })}
          </div>
        ) : (
          renderDynamicComponents(headerComponent, node_locale)
        )
      ) : null}
      <Container fluid className="d-flex homePageContainer">
        <div className="homeContentContainer">
          <Row className="firstSection">
            <Col sm={12} md={12} lg={8} xl={8}>
              {freeTextComponent &&
                renderDynamicComponents(freeTextComponent, node_locale)}
              {/* {additionalInfoComponent && (
                <div className="extraInfo">
                  <h2>{additionalInfoComponent[0].title}</h2>
                  <h4 className="font-weight-normal">{additionalInfoComponent[0].infoSub}</h4>
                  <p>{documentToReactComponents(additionalInfoComponent[0].body.json)}</p>
                 
                </div>
              )} */}
              {/* CTA removed because of new  requirements, uncomment if needed in the future*/}
              {/* <div className="btnContainerContent">
                <Link
                  className="button button--inverted button--link"
                  to={
                    "/past-event/frient-peacebuilding-forum-2021?r=documentation"
                  }
                  // to={link[index].slug}
                >
                  {getValue("labels.viewDocumentation", microcopy)}
                </Link>
              </div> */}
            </Col>
            <Col sm={12} md={12} lg={4} xl={4} className="sidebarContainer">
              {sidebarComponent && (
                <div className="sidebarInsideContainer">
                  {sidebarComponent[0]?.components.map(component => {
                    return (
                      <Box headline={component.title} className="sidebarBox">
                        <div className="infoBox">
                          <div>
                            {documentToReactComponents(component.body.json)}
                          </div>
                          <div className="btnContainer">
                            {component.link && (
                              <Button
                                type="primary"
                                link={component.link.url}
                                text={component.link.linkText}
                              />
                            )}
                            {component.internalLink && (
                              <Button
                                type="primary"
                                link={component.internalLink.slug}
                                text={getValue(
                                  `actions.view${component.internalLink.slug}`,
                                  microcopy
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </Box>
                    );
                  })}
                </div>
              )}
            </Col>
          </Row>

          {/* VOICES BLOG */}
          {/* <Row>
            <Col xs={12}>
              <Row as="section">
                <Col xs={6} as={"h6"}>
                  {getValue("labels.blog", microcopy)}
                </Col>
                <Col xs={6} className="text-right">
                  <Link
                    className={"home-read-more"}
                    to={getLocalizedLink(`/blog`, siteSettings.node_locale)}
                  >
                    {getValue("actions.seeAll", microcopy)}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="lg"
                      className="home-read-more-icon"
                    />
                  </Link>
                </Col>
              </Row>
              <Row>
                {allBlogArticle.slice(0, 3).map(article => (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TeaserCard
                      title={article.articleTitle}
                      image={article.imageLandscape.imageLandscape.fluid}
                      content={getCappedText(
                        _.get(article, [
                          "articleReader",
                          "content",
                          0,
                          "content",
                          0,
                          "value",
                        ]),
                        300
                      )}
                      tags={getLocalizedTags(
                        article.visibleTags,
                        [...article.topic, article.focusOfWork, article.region],
                        article.node_locale
                      )}
                      createdAt={moment(new Date(article.postDateTime)).format(
                        "DD MMMM YYYY"
                      )}
                      link={getArticleLink(
                        article.generalTitle,
                        article.node_locale
                      )}
                      type={article.type}
                      readMoreLabelText={getValue(
                        "actions.readMore",
                        microcopy
                      )}
                    ></TeaserCard>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row> */}

          {/* GALLERY & TWITTER */}
          <Row>
            <Col>
              <Row as="section">
                <Col xs={6} as={"h6"}>
                  {getValue("labels.gallery", microcopy)}
                </Col>
              </Row>
              <Row>
                <Col>
                  {objImages.images.length > 0 && (
                    <div className="fixedGallery">
                      <Gallery {...objImages} />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
      <Row>
        <Col xs={12} md={12} lg={12} xl={12} className="homeMembers">
          {memberComponent &&
            renderDynamicComponents(memberComponent, node_locale)}
        </Col>
      </Row>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    allContentfulBlogArticle: allContentfulArticleGeneral(
      filter: { articleDetail: { node_locale: { eq: $node_locale } } }
      limit: 9
      sort: { order: DESC, fields: articleDetail___postDateTime }
    ) {
      ...articleDetailFields
    }
  }
`;

export default HomePage;
